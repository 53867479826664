<template>
    <div v-wechat-title="this.title">
        <van-sticky :offset-top="0">
            <div class="page_top">
                <div class="date_choose">
                    <i class="icon iconfont iconarrow-left-filling" @click="subtractDate"></i>
                    <span class="date_info" @click="chooseCheckDay">{{date || '请选择'}}</span>
                    <i class="icon iconfont iconarrow-right-filling" @click="addDate"></i>
                </div>
                <van-search class="search_div" input-align="center" background="#F8F7FC" @search="reLoad" v-model="keyword" placeholder="患者姓名或手机号" />
            </div>
        </van-sticky>


        <van-calendar v-model="showCalendar" :default-date="defaultDate" :min-date="minDate" :show-confirm="false" @confirm="onConfirmCalendar" />

        <van-tabs
                v-model="qrystatus" :offset-top="62"
                @change="changeTab" class="status_div"
                color="#E69F12"
                title-active-color="#E69F12"
                sticky
                swipeable>

            <van-tab title="已预约" name="0" :badge="notreached" >
                <div class="treat_main_list" :class="{'bai': count0 <= 0}" >
                    <van-list
                            v-model="loading0"
                            :finished="finished0"
                            @load="onLoad"
                            :offset="30"
                            :immediate-check="false"
                            v-if="list0.length>0"
                            finished-text="加载完成">
                        <div class="one" v-for="item in list0" :key="item.apponintmentid">
                            <div class="name_appointment">
                                <div class="left">{{item.name}}</div>
                                <div class="right">
                                    <div class="appoinment_num" v-if="item.appointmentnum">{{item.appointmentnum}}</div>
                                    <div class="detail_info" @click="medical(item.userid, item.customerid)">
                                        <span>查看病历</span>
                                        <i class="icon iconfont iconsanjiaoyou"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="sex_age_phone">
                                <span>{{item.sex}} . {{item.age}}岁</span>
                                <span v-if="item.phone">{{item.phone}}</span>
                            </div>
                            <div class="title_top">
                                <div class="left">预约医生</div>
                                <div class="right">{{item.execdoctor}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">预约时间</div>
                                <div class="right">{{item.appointmenttime}}</div>
                            </div>
                            <div class="title_top" v-if="item.checknum">
                                <div class="left">报到号</div>
                                <div class="right">{{item.checknum}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">病情</div>
                                <div class="right left1" v-if="item.state && item.state.length > 15">{{item.state || '--'}}</div>
                                <div class="right" v-else>{{item.state || '--'}}</div>
                            </div>
                            <div class="button_info">
                                <div v-if="item.type == '1'" class="send" @click="send(item.apponintmentid)">推送预录病情</div>
                                <div class="photo" @click="takePicture(item.docid, item.userid)">拍照</div>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        当天无预约记录
                    </div>
                </div>
            </van-tab>

            <van-tab title="候诊中" name="1" :badge="wait" >
                <div class="treat_main_list" :class="{'bai': count1 <= 0}" >
                    <van-list
                            v-model="loading1"
                            :finished="finished1"
                            @load="onLoad"
                            :offset="30"
                            :immediate-check="false"
                            v-if="list1.length>0"
                            finished-text="加载完成">
                        <div class="one" v-for="item in list1" :key="item.apponintmentid">
                            <div class="name_appointment">
                                <div class="left">{{item.name}}</div>
                                <div class="right">
                                    <div class="appoinment_num" v-if="item.appointmentnum">{{item.appointmentnum}}</div>
                                    <div class="detail_info" @click="medical(item.userid, item.customerid)">
                                        <span>查看病历</span>
                                        <i class="icon iconfont iconsanjiaoyou"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="sex_age_phone">
                                <span>{{item.sex}} . {{item.age}}岁</span>
                                <span v-if="item.phone">{{item.phone}}</span>
                            </div>
                            <div class="title_top">
                                <div class="left">预约医生</div>
                                <div class="right">{{item.execdoctor}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">预约时间</div>
                                <div class="right">{{item.appointmenttime}}</div>
                            </div>
                            <div class="title_top" v-if="item.checknum">
                                <div class="left">报到号</div>
                                <div class="right">{{item.checknum}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">病情</div>
                                <div class="right">{{item.state || '--'}}</div>
                            </div>
                            <div class="button_info">
                                <div v-if="item.type == '1'" class="send" @click="send(item.apponintmentid)">推送预录病情</div>
                                <div class="photo" @click="takePicture(item.docid, item.userid)">拍照</div>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        当天无预约记录
                    </div>
                </div>
            </van-tab>

            <van-tab  title="就诊中" name="2" :badge="see">
                <div class="treat_main_list" :class="{'bai': count2 <= 0}">
                    <van-list
                            v-model="loading2"
                            :finished="finished2"
                            @load="onLoad"
                            :offset="30"
                            :immediate-check="false"
                            v-if="list2.length>0"
                            finished-text="加载完成">
                        <div class="one" v-for="item in list2" :key="item.apponintmentid">
                            <div class="name_appointment">
                                <div class="left">{{item.name}}</div>
                                <div class="right">
                                    <div class="appoinment_num" v-if="item.appointmentnum">{{item.appointmentnum}}</div>
                                    <div class="detail_info" @click="medical(item.userid, item.customerid)">
                                        <span>查看病历</span>
                                        <i class="icon iconfont iconsanjiaoyou"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="sex_age_phone">
                                <span>{{item.sex}} . {{item.age}}岁</span>
                                <span v-if="item.phone">{{item.phone}}</span>
                            </div>
                            <div class="title_top">
                                <div class="left">预约医生</div>
                                <div class="right">{{item.execdoctor}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">预约时间</div>
                                <div class="right">{{item.appointmenttime}}</div>
                            </div>
                            <div class="title_top" v-if="item.checknum">
                                <div class="left">报到号</div>
                                <div class="right">{{item.checknum}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">病情</div>
                                <div class="right">{{item.state || '--'}}</div>
                            </div>
                            <div class="button_info">
                                <div class="photo" @click="takePicture(item.docid, item.userid)">拍照</div>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        当天无预约记录
                    </div>
                </div>
            </van-tab>

            <van-tab  title="已完成" name="3" :badge="finish" >
                <div class="treat_main_list" :class="{'bai': count3 <= 0}">
                    <van-list
                            v-model="loading3"
                            :finished="finished3"
                            @load="onLoad"
                            :offset="30"
                            :immediate-check="false"
                            v-if="list3.length>0"
                            finished-text="加载完成">
                        <div class="one" v-for="item in list3" :key="item.apponintmentid">
                            <div class="name_appointment">
                                <div class="left">{{item.name}}</div>
                                <div class="right">
                                    <div class="appoinment_num" v-if="item.appointmentnum">{{item.appointmentnum}}</div>
                                    <div class="detail_info" @click="medical(item.userid, item.customerid)">
                                        <span>查看病历</span>
                                        <i class="icon iconfont iconsanjiaoyou"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="sex_age_phone">
                                <span>{{item.sex}} . {{item.age}}岁</span>
                                <span v-if="item.phone">{{item.phone}}</span>
                            </div>
                            <div class="title_top">
                                <div class="left">预约医生</div>
                                <div class="right">{{item.execdoctor}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">预约时间</div>
                                <div class="right">{{item.appointmenttime}}</div>
                            </div>
                            <div class="title_top" v-if="item.checknum">
                                <div class="left">报到号</div>
                                <div class="right">{{item.checknum}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">病情</div>
                                <div class="right">{{item.state || '--'}}</div>
                            </div>
                            <div class="button_info">
                                <div class="photo" @click="takePicture(item.docid, item.userid)">拍照</div>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        当天无预约记录
                    </div>
                </div>
            </van-tab>
        </van-tabs>

        <div class="user_main_list" v-if="list4.length>0">
            <div class="title">您要查询患者的病历</div>
            <div>
                <van-list
                    v-model="loading4"
                    :finished="finished4"
                    @load="loadUserList"
                    :offset="30"
                    :immediate-check="false"
                    finished-text="加载完成">
                <div class="one" v-for="(item, i) in list4" :key="i">
                    <div class="name_appointment">
                        <div class="left">{{item.name}}</div>
                        <div class="right">
                            <div class="detail_info" @click="medical(item.userid, item.customerid)">
                                <span>查看病历</span>
                                <i class="icon iconfont iconsanjiaoyou"></i>
                            </div>
                        </div>
                    </div>
                    <div class="sex_age_phone">
                        <span>{{item.sex}} . {{item.age}}岁</span>
                        <span v-if="item.phone">{{item.phone}}</span>
                    </div>
                </div>
            </van-list>
            </div>
        </div>

    </div>
</template>

<script>
    import { wxseestatistics,wxseeqry,wxsendpreinfo } from '@/api/medical'
    import { mapGetters } from 'vuex'
    // import Vue from 'vue';
    import { Toast } from 'vant';
    import Vue from 'vue';
    import { Calendar } from 'vant';

    Vue.use(Calendar);

    export default {
        computed: {
            ...mapGetters([
                'username','clinicid','isrefresh','isclose'
            ]),
        },
        data() {
            return {
                qrystatus: '0',
                list0: [],
                // 无限加载
                loading0: false,
                finished0: false,
                count0: 0,
                page0: 1,
                isload0: false,
                list1: [],
                // 无限加载
                loading1: false,
                finished1: false,
                count1: 0,
                page1: 1,
                isload1: false,
                list2: [],
                // 无限加载
                loading2: false,
                finished2: false,
                count2: 0,
                page2: 1,
                isload2: false,
                list3: [],
                // 无限加载
                loading3: false,
                finished3: false,
                count3: 0,
                page3: 1,
                isload3: false,
                keyword: '',
                notreached:0,
                wait: 0,
                see: 0,
                finish:0,
                minDate: new Date(2020, 0, 1),
                defaultDate: new Date(),
                date:'',
                showCalendar:false,
                title:'理疗工作台',
                loading: false,
                list4: [],
                // 无限加载
                loading4: false,
                finished4: false,
                count4: 0,
                page4: 1,
                isload4: false,
            }
        },
        mounted () {
            this.$store.dispatch('changeIsClose', '0')
            this.$store.dispatch('changeNav', '2')
            this.$store.dispatch('hideOrShowNav', false)
            this.getCurDate()
            Toast({
                message: '加载中...',
                type: 'loading',
                duration: 0
            })
            this.onLoad()
        },
        activated() {
            this.$store.dispatch('changeNav', '2')
            this.$store.dispatch('hideOrShowNav', false)
            console.log(this.isrefresh, '----isrefresh')
            if(this.isrefresh == '1' && !this.loading) {
                this.$store.dispatch('changeIsRefresh', '0')
                this.reLoad();
            }
        },
        methods: {
            goBack() {
                window.WeixinJSBridge.call('closeWindow')
            },
            getCurDate() {
                const nowDate = new Date();
                this.formatDate(nowDate)
            },
            addDate() {
                var d = new Date(this.date);
                d.setDate(d.getDate() + 1);
                this.formatDate(d)
                this.reLoad()
            },
            subtractDate() {
                var d = new Date(this.date);
                d.setDate(d.getDate() - 1);
                this.formatDate(d)
                this.reLoad()
            },
            formatDate(d) {
                const curDate = {
                    year: d.getFullYear(),
                    month: d.getMonth() + 1,
                    date: d.getDate(),
                }
                const newmonth = curDate.month>=10?curDate.month:'0'+curDate.month
                const day = curDate.date>=10?curDate.date:'0'+curDate.date
                this.date = curDate.year + '-' + newmonth + '-' + day
                this.defaultDate = new Date(this.date);
            },
            chooseCheckDay(){
                this.showCalendar = true
            },
            onConfirmCalendar(date){
                this.showCalendar = false
                this.formatDate(date);
                Toast({
                    message: '加载中...',
                    type: 'loading',
                    duration: 0
                })
                this.reLoad()
            },

            changeTab(){
                if(this.qrystatus === '0' && this.isload0) {
                    return
                }
                if(this.qrystatus === '1' && this.isload1) {
                    return
                }
                if(this.qrystatus === '2' && this.isload2) {
                    return
                }
                if(this.qrystatus === '3' && this.isload3) {
                    return
                }
                Toast({
                    message: '加载中...',
                    type: 'loading',
                    duration: 0
                })
                this.onLoad()
            },
            reLoad(){
                this.finished0 = false
                this.loading0 = false
                this.count0 = 0
                this.page0 = 1
                this.list0 = []
                this.isload0 = false

                this.finished1 = false
                this.loading1 = false
                this.count1 = 0
                this.page1 = 1
                this.list1 = []
                this.isload1 = false

                this.finished2 = false
                this.loading2 = false
                this.count2 = 0
                this.page2 = 1
                this.list2 = []
                this.isload2 = false

                this.finished3 = false
                this.loading3 = false
                this.count3 = 0
                this.page3 = 1
                this.list3 = []
                this.isload3 = false

                this.finished4 = false
                this.loading4 = false
                this.count4 = 0
                this.page4 = 1
                this.list4 = []
                this.isload4 = false

                this.onLoad()
            },
            onLoad(){
                console.log(this.clinicid, '*****this.clinicid')
                if(this.clinicid == null || this.clinicid == '' || this.clinicid == undefined
                    || this.username == null || this.username == '' || this.username == undefined) {
                    Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: '未登录或未选择诊所，页面关闭',
                    });
                    let second = 1;
                    const timer = setInterval(() => {
                        second--;
                        if (!second) {
                            clearInterval(timer);
                            // 手动清除 Toast
                            Toast.clear();
                            this.goBack()
                        }
                    }, 1000);
                    return;

                }
                this.loading = true
                if(this.qrystatus === '0' && this.finished0) {
                    return
                }
                if(this.qrystatus === '1' && this.finished1) {
                    return
                }
                if(this.qrystatus === '2' && this.finished2) {
                    return
                }
                if(this.qrystatus === '3' && this.finished3) {
                    return
                }

                let sdata = {
                    username: this.username,
                    qrydate: this.date,
                    keyword: this.keyword,
                    clinicid: this.clinicid
                }
                wxseestatistics(sdata).then(response => {
                    if(response.response_body){
                        let old_notreached = this.notreached;
                        let old_wait = this.wait;
                        let old_see = this.see;
                        let old_finish = this.finish;
                        this.notreached = parseInt(response.response_body.notreached)
                        if(this.isload0 && this.notreached != old_notreached) {
                            this.finished0 = false
                            this.loading0 = false
                            this.count0 = 0
                            this.page0 = 1
                            this.list0 = []
                            this.isload0 = false
                        }
                        this.wait = parseInt(response.response_body.wait)
                        if(this.isload1 && this.wait != old_wait) {
                            this.finished1 = false
                            this.loading1 = false
                            this.count1 = 0
                            this.page1 = 1
                            this.list1 = []
                            this.isload1 = false
                        }
                        this.see = parseInt(response.response_body.see)
                        if(this.isload2 && this.see != old_see) {
                            this.finished2 = false
                            this.loading2 = false
                            this.count2 = 0
                            this.page2 = 1
                            this.list2 = []
                            this.isload2 = false
                        }
                        this.finish = parseInt(response.response_body.finish)
                        if(this.isload3 && this.finish != old_finish) {
                            this.finished3 = false
                            this.loading3 = false
                            this.count3 = 0
                            this.page3 = 1
                            this.list3 = []
                            this.isload3 = false
                        }
                    }

                    let qryPage = 1;
                    if(this.qrystatus === '0') {
                        qryPage = this.page0
                    }
                    if(this.qrystatus === '1') {
                        qryPage = this.page1
                    }
                    if(this.qrystatus === '2') {
                        qryPage = this.page2
                    }
                    if(this.qrystatus === '3') {
                        qryPage = this.page3
                    }
                    let data = {
                        username: this.username,
                        qrydate: this.date,
                        keyword: this.keyword,
                        page: qryPage + '',
                        qrystatus: this.qrystatus,
                        clinicid: this.clinicid
                    }
                    wxseeqry(data).then(response => {
                        if(Toast){
                            Toast.clear()
                        }
                        if(this.qrystatus === '0') {
                            this.isload0 = true
                        }
                        if(this.qrystatus === '1') {
                            this.isload1 = true
                        }
                        if(this.qrystatus === '2') {
                            this.isload2 = true
                        }
                        if(this.qrystatus === '3') {
                            this.isload3 = true
                        }
                        if(response.response_body && response.response_body.list){
                            if(this.qrystatus === '0') {
                                this.count0 = parseInt(response.response_body.num)
                                this.list0 = this.list0.concat(response.response_body.list)
                                this.finished0 = (this.page0 >= response.response_body.pages);
                                this.page0 = parseInt(this.page0)+1
                            }
                            if(this.qrystatus === '1') {
                                this.count1 = parseInt(response.response_body.num)
                                this.list1 = this.list1.concat(response.response_body.list)
                                this.finished1 = (this.page1 >= response.response_body.pages);
                                this.page1 = parseInt(this.page1)+1
                            }
                            if(this.qrystatus === '2') {
                                this.count2 = parseInt(response.response_body.num)
                                this.list2 = this.list2.concat(response.response_body.list)
                                this.finished2 = (this.page2 >= response.response_body.pages);
                                this.page2 = parseInt(this.page2)+1
                            }
                            if(this.qrystatus === '3') {
                                this.count3 = parseInt(response.response_body.num)
                                this.list3 = this.list3.concat(response.response_body.list)
                                this.finished3 = (this.page3 >= response.response_body.pages);
                                this.page3 = parseInt(this.page3)+1
                            }
                            if(response.response_body.usernum && response.response_body.usernum > 0) {
                                this.count4 = parseInt(response.response_body.usernum)
                                this.list4 = this.list4.concat(response.response_body.userlist)
                                this.finished4 = (this.page4 >= response.response_body.userpages);
                                this.page4 = parseInt(this.page4)+1
                            }
                        }
                        if(this.qrystatus === '0') {
                            // 加载状态结束
                            this.loading0 = false;
                        }
                        if(this.qrystatus === '1') {
                            // 加载状态结束
                            this.loading1 = false;
                        }
                        if(this.qrystatus === '2') {
                            // 加载状态结束
                            this.loading2 = false;
                        }
                        if(this.qrystatus === '3') {
                            // 加载状态结束
                            this.loading3 = false;
                        }
                        this.loading4 = false
                        this.loading = false
                    })
                })


            },

            loadUserList(){
                this.loading = true
                let qryPage = this.page4
                let data = {
                    username: this.username,
                    qrydate: this.date,
                    keyword: this.keyword,
                    page: qryPage + '',
                    qrystatus: this.qrystatus,
                    clinicid: this.clinicid,
                    qryuser: '1'
                }
                wxseeqry(data).then(response => {
                    if(Toast){
                        Toast.clear()
                    }
                    this.isload4 = true
                    if(response.response_body && response.response_body.userlist) {
                        this.count4 = parseInt(response.response_body.usernum)
                        this.list4 = this.list4.concat(response.response_body.userlist)
                        this.finished4 = (this.page4 >= response.response_body.userpages);
                        this.page4 = parseInt(this.page4)+1
                    }
                    this.loading4 = false
                    this.loading = false
                })
            },

            medical(userid, customerid) {
                this.$router.push({
                    path: '/medicalPage',
                    query: {
                        userid: userid,
                        customerid: customerid
                    }
                })
            },
            send(appointmentid) {
                Toast({
                    message: '准备推送预录病情消息至患者微信广州颐中中医公众号上...',
                    type: 'loading',
                    duration: 0
                })
                let data = {
                    username: this.username,
                    id: appointmentid
                }
                wxsendpreinfo(data).then(() => {
                    if(Toast){
                        Toast.clear()
                    }
                    Toast.success('推送成功');
                })
            },
            takePicture(docId, userId) {
                this.$router.push({
                    path: '/takePicture',
                    query: {
                        docId: docId,
                        userId: userId
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .van-info{
        top: 2px;
        right: -8px;
    }
    .van-cell{
        background-color: #F8F7FC;
        border-radius: 0.50667rem;
    }
    .bai {
        background-color: #ffffff;
    }
</style>